import * as React from 'react'
import { BellAlertIcon } from '@heroicons/react/24/solid'
import FormWrapper from '../components/form/FormWrapper'
import InputField from '../components/form/InputField'
import isEmail from '../components/form/validators/isEmail'
import useFormState from '../components/form/hooks/useFormState'

const RegisterSMS = () => {
  const [form, setForm, updateForm] = useFormState({
    fName: '',
    lName: '',
    phone: '',
    //   email: '',
    expandRequest: true,
    consent: true,
  })

  const onChangeHandler = e => {
    setForm(f => ({ ...f, [e.target.name]: e.target.checked }))
  }

  return (
    <div className="relative w-full flex flex-col lg:flex-row justify-center items-center">
      <div className="lg:min-h-screen w-full lg:w-1/2 flex flex-col justify-center items-center lg:items-end px-5 shadow-2xl min-h-[50vh]">
        <div className="flex flex-col lg:grid grid-cols-[1fr_auto] items-center justify-center">
          <BellAlertIcon className="w-20 h-20 text-red-600 m-2 " />
          <div>
            <h1 className="text-3xl lg:text-5xl font-black ">GET NOTIFIED!</h1>
            <h2 className="lg:text-xl">SIGN UP FOR SMS UPDATES</h2>
          </div>
        </div>
      </div>
      <div className="lg:min-h-screen lg:w-1/2 w-full bg-stone-200 flex flex-col justify-center px-5 min-h-[50vh]">
        <FormWrapper
          form={form}
          name={'Rate Your Experience'}
          className=" md:pr-2 w-full max-w-[500px] "
        >
          <InputField
            name={'name'}
            onChange={updateForm}
            form={form}
            label="Full Name"
            showErrorMessage={false}
            required={true}
            // className="--home-subscribe-animations"
          />
          <InputField
            type={'phone'}
            name={'phone'}
            onChange={updateForm}
            form={form}
            label="Telephone"
            validator={isEmail}
            required={true}
            showErrorMessage={false}
          />
          <label className="text-xs text-gray-500 flex flex-row gap-3 items-start my-2">
            <input
              type="checkbox"
              name="consent"
              checked={form['consent']}
              onChange={onChangeHandler}
            />
            I agree to receive updates and follow-up messages about my inquiries via text and email.
          </label>
          <button className="red-raised-btn --home-subscribe-animations delay2 my-4 px-5 py-1">
            Subscribe
          </button>
        </FormWrapper>
      </div>
    </div>
  )
}

export default RegisterSMS
